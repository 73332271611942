const convertToUSCurrency = (priceToBeConverted: number | undefined | null) => {
  if (priceToBeConverted === null || priceToBeConverted === undefined)
    return 'N/A'
  else {
    let currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })
    return currencyFormatter.format(priceToBeConverted)
  }
}

export { convertToUSCurrency }
