import {
  IoIosArrowDown as ArrowDown,
  IoIosArrowUp as ArrowUp,
} from 'react-icons/io'
import { Checkbox, Rating, Table, TableBody } from '@mui/material'
import React, { useEffect, useState } from 'react'

import ApiManager from '../../../../api/ApiManager'
import Button from '../../../common/Button/Button.component'
import CardTable from '../../Common/CardTable.component'
import CarrierLogo from '../../../common/CarrierLogo'
import EnrollmentRequestConfirmation from '../../Modals/EnrollmentRequestConfirmation'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MAPlan } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MAPlan'
import { MNonSupplementPlan } from '../../../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MedicalCost } from '../../../../interface/quotes/mNonSupplementPlans/MedicalCost'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import PharmacyCoverage from '../../PharmacyCoverage.component'
import PrescriptionCoverage from '../../PrescriptionCoverage.component'
import ProviderCoverage from '../../ProviderCoverage.component'
import SavePlanIconButton from '../../../common/SavePlan/SavePlanIconButton'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import UrlConstants from '../../../../constants/UrlConstants'
import _ from 'lodash'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { fireEvent } from '../../../../cra'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useNavigate } from 'react-router-dom'
import userActionStore from '../../../../datastore/UserActionStore'

// import Tooltip from '../../../common/Tooltip/Tooltip.component'

const mccpdc =
  process.env.REACT_APP_CDN_BASE_URL +
  '/carriers/cost-plus-drug-company-logo.png'

interface MedicareAdvantageCardProps {
  medicareAdvantagePlan: MNonSupplementPlan
  onEnroll: () => void
  handleRemoveFavorite?: () => void
  showCompareCheckbox?: boolean
}
interface PlanBenefit {
  name: string
  covered: boolean
  deductible?: number
}

interface EstimatedAnnualCostProps {
  cost: number
  title: string
  tooltipTitle: string
}

const Container = styled.div`
  width: 900px;
  border: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px;
  overflow: hidden;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const HighlightChip = styled.div<{ type: string }>`
  padding: 10px 30px;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.type === 'current'
      ? `${theme.colors.primary}`
      : `${theme.colors.green}`};
  p {
    font-size: 15px;
    font-weight: 700;
    color: ${theme.colors.textLight};
    span {
      font-weight: 400;
      font-style: italic;
    }
  }
  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    margin-top: 2px;
  }
`

const Wrapper = styled.div`
  padding: 0px 30px;
  @media screen and (max-width: 530px) {
    padding: 0px 15px;
  }
`

const Header = styled.div`
  padding: 4px 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      margin-right: 20px;
    }
    h2 {
      font-size: 16px;
      color: ${theme.colors.textDark};
    }
    span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 15px 10px;
    flex-direction: column;
    div {
      img {
        width: 60px;
        margin-right: 10px;
      }
      h2 {
        font-size: 14px;
      }
      span {
        font-size: 12px;
      }
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 12px 0px;

  @media screen and (max-width: 530px) {
    padding: 20px 0px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const BenefitsWrapper = styled.div`
  flex: 1.5;
  border-right: 1px solid ${theme.colors.borderColor};
  padding: 0px 30px;
  @media screen and (max-width: 530px) {
    padding: 0px 10px;
  }

  @media screen and (max-width: 500px) {
    border-right: none;
    border-bottom: 1px solid ${theme.colors.borderColor};
    margin-bottom: 20px;
  }
`

const PricingWrapper = styled.div`
  flex: 1;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    padding-bottom: 5px;
  }
  p {
    font-size: 24px;
    font-weight: bold;
    color: ${theme.colors.primary};
    padding-bottom: 10px;
  }

  div {
    padding: 4px 0px 0px 0px;
    p {
      font-size: 14px;
      padding-bottom: 0px;
    }
    span {
      font-size: 12px;
      color: ${theme.colors.textGrey};
      font-weight: 600;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 0px 10px;
  }
`
const OutOfPocketExpenses = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  margin-bottom: 3px;
`

const BenefitContent = styled.div`
  padding-bottom: 12px;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    padding-bottom: 8px;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    p {
      padding-right: 15px;
      padding-bottom: 3px;
      font-size: 14px;
      color: ${theme.colors.textGrey};
      display: flex;
      align-items: center;
      span {
        margin-right: 2px;
      }
    }
  }
`

const FooterWrapper = styled.div`
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
    }
    p {
      color: ${theme.colors.primary};
      font-size: 14px;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 0px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    div {
      flex-wrap: wrap;
      span {
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }
`

const MoreDetailsWrapper = styled.div<{ isExpanded: boolean }>`
  padding: 20px;
  display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
  width: 100%;
  p {
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 700;
    color: ${theme.colors.textGrey};
    span {
      padding-left: 10px;
      text-decoration: underline;
      font-weight: 400;
      color: ${theme.colors.primary};
      font-size: 12px;
    }
  }
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundColorContainer};
  }
`

const TableCell = styled(MuiTableCell)`
  font-weight: 700;
  color: ${theme.colors.textGrey};
  font-size: 14px;
`

const TextWrapper = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #2f3032;
  padding-right: 10px;
`

const EstimatedAnnualCostContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px !important;
  text-align: left;
  border-top: 1px solid ${theme.colors.borderColor};
  padding: 16px 0px 0px 0px !important;
  margin-top: 20px;

  span {
    font-weight: 700 !important;
  }
`

const MedicareAdvantageCard: React.FC<MedicareAdvantageCardProps> = ({
  medicareAdvantagePlan,
  onEnroll,
  handleRemoveFavorite,
  showCompareCheckbox = true,
}) => {
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [enrollRequested, setEnrollRequested] = React.useState(
    medicareAdvantagePlan.enrollRequested
  )
  const [enrollmentRequestPromptActive, setEnrollmentRequestPromptActive] =
    React.useState(false)
  const enrollBtnText = enrollRequested ? 'Enrollment Requested' : 'ENROLL'
  const [loading, setLoading] = React.useState(false)
  const handleExpandCard = (maPlanName: string) => {
    if (isExpanded === true) {
      customerStore.setSearchPlan('')
      customerStore.setScrollPosition('')
      setIsExpanded(false)
    }
    if (isExpanded === false) {
      setIsExpanded(true)
    }
    if (medicareAdvantagePlan.name === customerStore.searchPlan) {
      setIsExpanded(false)
      customerStore.setSearchPlan('')
      customerStore.setScrollPosition('')
    }
  }
  const [maPlanCard, setMAPlanCard] = useState<MNonSupplementPlan>()
  const [isFavorite, setIsFavorite] = useState(
    medicareAdvantagePlan.planSaved || false
  )
  const [preferredPhone, setPreferredPhone] = React.useState(
    customerStore.get().phone.replace('+1', '')
  )
  //let drugHighlights: { key: string; value: string }[] = []
  // const [currentPlanAnnualCost, setCurrentPlanAnnualCost] =
  //   React.useState<number>(0)
  // const [currentPlanExist, setCurrentPlanExistStatus] =
  //   React.useState<boolean>(false)

  let planBenefits: PlanBenefit[] = []

  const handleEnrollRequest = () => {
    setLoading(true)
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_ENROLL_REQUESTED.USECASE,
      HttpMethods.PATCH_METHOD,
      {
        $planId: medicareAdvantagePlan.id,
        quoteType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
        enrollRequested: true,
        versionId: process.env.REACT_APP_VERSION_ID,
        forSpouse: false,
        preferredPhoneNumber: '+1' + preferredPhone,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Enroll request saved successfully',
            snackbarType: 'success',
          })
          setEnrollRequested(true)
          customerStore.setEnrollmentRequestedPlan({
            planId: medicareAdvantagePlan.id,
            planType: MedicareQuotesType.MEDICARE_ADVANTAGE,
          })
          setEnrollmentRequestPromptActive(false)
          onEnroll()
        } else throw response
      })
      .catch((err) => {
        if (
          err.data?.message ===
          'Maximum limit reached for requesting enrollment for plans'
        ) {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              'save enroll request. Maximum limit reached'
            ),
            snackbarType: 'error',
          })
          setEnrollRequested(false)
          setEnrollmentRequestPromptActive(false)
        } else {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage('save enroll request'),
            snackbarType: 'error',
          })
          setEnrollRequested(false)
          setEnrollmentRequestPromptActive(false)
        }
      })
  }

  useEffect(() => {
    setMAPlanCard(medicareAdvantagePlan)
  }, [medicareAdvantagePlan])

  const onAddFavPlan = (planId: string) => {
    setIsFavorite(true)
  }

  const onRemoveFavPlan = (planId: string) => {
    setIsFavorite(false)
    handleRemoveFavorite && handleRemoveFavorite()
  }

  if (maPlanCard === null) {
    return null
  }

  let packageBenefits: any = {
    hasVision: medicareAdvantagePlan.hasVision,
    hasTransport: medicareAdvantagePlan.hasTransport,
    hasDental: medicareAdvantagePlan.hasDental,
    hasHearing: medicareAdvantagePlan.hasHearing,
    hasOTC: medicareAdvantagePlan.hasOTC,
    hasPreferredPharmacies: medicareAdvantagePlan.hasPreferredPharmacies,
    hasInsulinSavings: medicareAdvantagePlan.hasInsulinSavings,
    acceptsMailOrder: medicareAdvantagePlan.acceptsMailOrder,
    partBReimbursement: medicareAdvantagePlan.partBReimbursement,
  }

  Object.keys(packageBenefits).forEach((benefit) => {
    let covered = !(
      packageBenefits[benefit] === 'N' ||
      packageBenefits[benefit] === false ||
      packageBenefits[benefit] === -1
    )
    benefit = /^has/.test(benefit) ? benefit.slice(3) : benefit
    planBenefits.push({
      name: _.startCase(benefit).replace(/\s/g, ' '),
      covered: covered,
      deductible: undefined,
    })
  })

  const handleAddToCompare = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      // If same plan type -> Check if length > 3. If no, send request. If yes, show message
      if (
        userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_ADVANTAGE
      ) {
        if (userActionStore.comparePlanCards.compareCardsData.length < 3) {
          userActionStore.addCard(MedicareQuotesType.MEDICARE_ADVANTAGE, {
            id: medicareAdvantagePlan.id,
            name: medicareAdvantagePlan.name,
            premium: medicareAdvantagePlan.premium,
            carrier: medicareAdvantagePlan.carrierName,
            enrollRequestStatus: medicareAdvantagePlan.enrollRequested,
            planType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
            sunfirePlanId: medicareAdvantagePlan.sunfirePlanId,
            rating: medicareAdvantagePlan.fmtRating,
          })
        } else {
          snackbarStore.set({
            snackbarMessage: 'You can only add up to 3 plans for comparison',
            snackbarOpen: true,
            snackbarType: 'default',
          })
        }
      } else {
        // If switched to a different plan type, just send the information. The state will
        // reset and count will start from 0
        userActionStore.addCard(MedicareQuotesType.MEDICARE_ADVANTAGE, {
          id: medicareAdvantagePlan.id,
          name: medicareAdvantagePlan.name,
          premium: medicareAdvantagePlan.premium,
          carrier: medicareAdvantagePlan.carrierName,
          enrollRequestStatus: medicareAdvantagePlan.enrollRequested,
          planType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
          sunfirePlanId: medicareAdvantagePlan.sunfirePlanId,
          rating: medicareAdvantagePlan.fmtRating,
        })
      }
    }

    if (!e.currentTarget.checked) {
      userActionStore.removeCard(medicareAdvantagePlan.id)
    }
  }

  const storeScrollPosition = () => {
    const scrollPosition = window.scrollY
    customerStore.setScrollPosition(scrollPosition.toString())
  }

  const EstimatedAnnualCost: React.FC<EstimatedAnnualCostProps> = ({
    cost,
    title,
    tooltipTitle,
  }) => (
    <div>
      <EstimatedAnnualCostContainer>
        <TextWrapper>{convertToUSCurrency(cost)}</TextWrapper>
        <div style={{ width: '100%' }}>
          <h2 style={{ paddingTop: '0px', fontSize: '14px' }}>{title}</h2>
        </div>
        <div
          style={{
            paddingTop: '0px',
            marginLeft: '0px!important',
          }}
        >
          <Tooltip
            title={tooltipTitle}
            placement={
              title === 'Estimated Annual Cost' ? 'top-start' : 'bottom-start'
            }
          />
        </div>
      </EstimatedAnnualCostContainer>
    </div>
  )

  return (
    <>
      {maPlanCard && (
        <Container>
          {/* {medicareAdvantagePlan.isRecommended && (
            <HighlightChip type='Recommended'>
              <img src={recommendedIcon}></img>
              <p>Recommended</p>
            </HighlightChip>
          )} */}
          {enrollmentRequestPromptActive && (
            <ModalComponent
              setOpen={() => {
                setEnrollmentRequestPromptActive(false)
              }}
              description='Popup - Request for Enrollment Confirmation'
              hideCloseButton={true}
            >
              <EnrollmentRequestConfirmation
                setOpen={() => {
                  setEnrollmentRequestPromptActive(false)
                }}
                onEnroll={() => {
                  handleEnrollRequest()
                }}
                planName={medicareAdvantagePlan.name}
                planType={MedicareQuotesType.MEDICARE_ADVANTAGE}
                planId={medicareAdvantagePlan.id}
                buttonLoading={loading}
                preferredPhoneNumber={preferredPhone}
                setPreferredPhoneNumber={setPreferredPhone}
              />
            </ModalComponent>
          )}

          <Wrapper>
            <Header>
              <div>
                <CarrierLogo
                  carrier={maPlanCard.carrierName}
                  useAltLogo={true}
                />
                <h2>{maPlanCard.name}</h2>
              </div>
              {showCompareCheckbox && (
                <div>
                  <Checkbox
                    checked={
                      !!userActionStore.comparePlanCards.compareCardsData.find(
                        (iterator) => iterator.id === maPlanCard.id
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleAddToCompare(e)
                      fireEvent(
                        !!userActionStore.comparePlanCards.compareCardsData.find(
                          (iterator) => iterator.id === maPlanCard.id
                        )
                          ? 'addCardForComparison'
                          : 'removeAddedPlanForComparison',
                        e,
                        {
                          description:
                            !!userActionStore.comparePlanCards.compareCardsData.find(
                              (iterator) => iterator.id === maPlanCard.id
                            )
                              ? 'Add Card for Comparison'
                              : 'Remove Card from Comparison',
                          planId: maPlanCard.planId,
                          planType: maPlanCard.type,
                          planName: maPlanCard.name,
                        }
                      )
                    }}
                  />
                  <span>Add to compare</span>
                </div>
              )}
            </Header>
            <ContentWrapper>
              <BenefitsWrapper>
                <BenefitContent>
                  <h2>
                    Star Rating
                    {maPlanCard.fmtRating === -1 && (
                      <Tooltip
                        title={
                          StringConstants.TOOLTIP_NON_ELIGIBLE_STAR_RATING_MESSAGE
                        }
                      />
                    )}
                  </h2>
                  <Rating
                    name='simple-controlled'
                    value={maPlanCard.fmtRating || 0}
                    precision={0.5}
                    readOnly={true}
                    sx={{
                      marginBottom: '1.5rem',
                    }}
                  />
                  <OutOfPocketExpenses>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <h2>Maximum Out of Pocket </h2>
                      <Label>
                        <Tooltip
                          title={StringConstants.TOOLTIP_MSG_MOOP_IN_NETWORK}
                        />
                      </Label>
                    </div>
                    <p
                      style={{
                        color: `${theme.colors.primary}`,
                        fontSize: '14px',
                        fontWeight: 'bold',
                        paddingBottom: '6px',
                      }}
                    >
                      {convertToUSCurrency(maPlanCard.moop)} (in-network)
                    </p>
                  </OutOfPocketExpenses>
                  {medicareAdvantageFilterQuoteStore.getIsRxIncluded() &&
                  (maPlanCard.contractYear === new Date().getFullYear() ||
                    (maPlanCard.contractYear === new Date().getFullYear() + 1 &&
                      customerStore.getCalculateDrugCostForAEP())) ? (
                    <>
                      <PrescriptionCoverage
                        prescribedDrugsCoverage={maPlanCard.drugInformations}
                      />
                    </>
                  ) : null}

                  <ProviderCoverage
                    providerCoverages={medicareAdvantagePlan.providerCoverage}
                  ></ProviderCoverage>

                  {maPlanCard.drugCosts?.length > 0 && (
                    <PharmacyCoverage
                      pharmacyCoverages={medicareAdvantagePlan.drugCosts}
                    ></PharmacyCoverage>
                  )}
                </BenefitContent>
              </BenefitsWrapper>
              <PricingWrapper>
                <h2>Monthly Premium</h2>
                <p>{convertToUSCurrency(maPlanCard.premium)}</p>
                <Button
                  variant='contained'
                  color='secondary'
                  width='100%'
                  onClick={() => {
                    setEnrollmentRequestPromptActive(true)
                  }}
                  disabled={enrollRequested}
                  additionalPadding={true}
                  description={`Attempted Enrollment in ${medicareAdvantagePlan.name}`}
                  id={`enroll ${medicareAdvantagePlan.name}`}
                >
                  {enrollBtnText}
                </Button>
                <div>
                  {medicareAdvantagePlan.drugCosts?.length > 0 && (
                    <EstimatedAnnualCostContainer>
                      <TextWrapper>
                        {convertToUSCurrency(maPlanCard.drugCost)}
                      </TextWrapper>
                      <div style={{ width: '100%' }}>
                        <h2 style={{ paddingTop: '0px', fontSize: '14px' }}>
                          Estimated Annual Cost
                        </h2>
                      </div>
                      <div
                        style={{
                          paddingTop: '0px',
                          marginLeft: '0px!important',
                        }}
                      >
                        <Tooltip
                          title={StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST}
                          placement='top-start'
                        />
                      </div>
                    </EstimatedAnnualCostContainer>
                  )}
                </div>
                {/* {currentPlanExist && (
                  <div>
                    <p>
                      {convertToUSCurrency(
                        Math.abs(
                          currentPlanAnnualCost - maPlanCard.estimatedAnnualCost
                        )
                      )}
                    </p>
                    <span>Estimated Annual Savings</span>
                  </div>
                )} */}
                {/* <div>
                  <span>
                    Estimated Annual Cost{' '}
                    <Tooltip
                      title='The Estimated Annual Cost of a plan is calculated based
                      on the expected cost of your doctor visits,
                      your prescription drugs cost, the plan type etc.'
                    />
                  </span>
                  <p>{convertToUSCurrency(maPlanCard.estimatedAnnualCost)}</p>
                </div> */}
              </PricingWrapper>
            </ContentWrapper>
            <FooterWrapper>
              <SavePlanIconButton
                planId={maPlanCard.id}
                planType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
                saved={isFavorite}
                onAddSaved={onAddFavPlan}
                onRemoveSaved={onRemoveFavPlan}
              />
              {maPlanCard && (
                <div>
                  <Button
                    variant='text'
                    color='primary'
                    fontSize='14px'
                    borderRadius='4px'
                    bold={false}
                    endIcon={
                      isExpanded ||
                      medicareAdvantagePlan.name ===
                        customerStore.searchPlan ? (
                        <ArrowUp style={{ fontSize: '12px' }} />
                      ) : (
                        <ArrowDown style={{ fontSize: '12px' }} />
                      )
                    }
                    description={
                      isExpanded ||
                      medicareAdvantagePlan.name === customerStore.searchPlan
                        ? 'View Less Details'
                        : 'View More Details'
                    }
                    onClick={(e) => {
                      handleExpandCard(medicareAdvantagePlan.name)
                      fireEvent(
                        isExpanded ||
                          medicareAdvantagePlan.name ===
                            customerStore.searchPlan
                          ? 'viewLessCardDetails'
                          : 'viewMoreCardDetails',
                        e,
                        {
                          value:
                            !isExpanded ||
                            medicareAdvantagePlan.name !==
                              customerStore.searchPlan
                              ? 'expanding'
                              : 'collapsing',
                          planId: maPlanCard.id,
                          planType: MedicareQuotesType.MEDICARE_ADVANTAGE,
                          description:
                            isExpanded ||
                            medicareAdvantagePlan.name ===
                              customerStore.searchPlan
                              ? 'View Less Card Details'
                              : 'View More Card Details',
                        }
                      )
                    }}
                  >
                    <>
                      View{' '}
                      {isExpanded ||
                      medicareAdvantagePlan.name === customerStore.searchPlan
                        ? 'less'
                        : 'more'}{' '}
                      details
                    </>
                  </Button>
                </div>
              )}
            </FooterWrapper>
            <MoreDetailsWrapper
              isExpanded={
                isExpanded ||
                medicareAdvantagePlan.name === customerStore.searchPlan
              }
            >
              {maPlanCard.medicalCosts && (
                <>
                  <p>
                    Plan Highlights{' '}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        fireEvent('viewCompletePlanDetails', e, {
                          description:
                            'View Complete Plan Details of Medicare Advantage Card',
                          planId: maPlanCard.id,
                          planType: maPlanCard.type,
                          planName: maPlanCard.name,
                        })
                        navigate({
                          pathname: '/plan-details',
                          search: `id=${maPlanCard.id}&&type=ma`,
                        })
                        storeScrollPosition()
                      }}
                    >
                      View complete plan details
                    </span>
                  </p>

                  <CardTable
                    rows={[
                      {
                        key: 'Primary Doctor Visit Cost',
                        value:
                          convertToUSCurrency(
                            maPlanCard.medicalCosts.find(
                              (cost: MedicalCost) =>
                                cost.name === 'Doctor Office Visits'
                            )?.costs[0]?.minCopay
                          ) || '$0',
                      },
                      {
                        key: 'Specialist Doctor Visit Cost',
                        value:
                          convertToUSCurrency(
                            maPlanCard.medicalCosts.find(
                              (cost: MedicalCost) =>
                                cost.name === 'Doctor Specialty Visit'
                            )?.costs[0]?.minCopay
                          ) || '$0',
                      },
                      {
                        key: 'Emergency Care Visit Cost',
                        value:
                          convertToUSCurrency(
                            maPlanCard.medicalCosts.find(
                              (cost: MedicalCost) =>
                                cost.name === 'Emergency Care'
                            )?.costs[0]?.minCopay
                          ) || '$0',
                      },
                      {
                        key: 'Annual Deductible',
                        value: maPlanCard.fmtDeductible,
                      },
                      {
                        key: 'Maximum Out of Pocket',
                        value: maPlanCard.fmtMoop,
                      },
                    ]}
                  />
                </>
              )}
              {planBenefits?.length > 0 && (
                <>
                  <p>
                    Plan Benefits{' '}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        fireEvent('viewCompletePlanDetails', e, {
                          description:
                            'View Complete Plan Details of Medicare Advantage Card',
                          planId: maPlanCard.id,
                          planType: maPlanCard.type,
                          planName: maPlanCard.name,
                        })
                        navigate({
                          pathname: '/plan-details',
                          search: `id=${maPlanCard.id}&&type=ma`,
                        })
                        storeScrollPosition()
                      }}
                    >
                      View complete plan details
                    </span>
                  </p>
                  <CardTable
                    rows={planBenefits.map((iterator) => ({
                      key: iterator.name,
                      value: iterator.covered ? 'Yes' : 'No',
                    }))}
                  />
                </>
              )}
              {/* {maPlanCard.prescriptionCoverages?.length > 0 &&
                maPlanCard.prescriptionCoverages?.some(
                  (prescriptionCoverage) =>
                    prescriptionCoverage.pharmacyName ===
                      'MAIL_ORDER_PHARMACY' ||
                    (prescriptionCoverage.pharmacyName !==
                      'MAIL_ORDER_PHARMACY' &&
                      !isEmpty(prescriptionCoverage.pharmacyAddress))
                ) && (
                  <>
                    <p>
                      Drug Prices (for the rest of the year){' '}
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          fireEvent('viewCompletePlanDetails', e, {
                            description:
                              'View Complete Plan Details of Medicare Advantage Card',
                            planId: maPlanCard.id,
                            planType: maPlanCard.planType,
                            planName: maPlanCard.name,
                          })

                          navigate({
                            pathname: '/plan-details',
                            search: `id=${maPlanCard.id}&&type=ma`,
                          })
                          storeScrollPosition()
                        }}
                      >
                        View complete plan details
                      </span>
                    </p>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={1}>Drug Name</TableCell> */}
              {/* <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                          <div>
                            {' '}
                            Cost Plus Price
                            <a
                              href='https://costplusdrugs.com/?utm_source=eip&utm_medium=eip'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <img
                                style={{
                                  width: '90px',
                                  verticalAlign: 'middle',
                                  marginLeft: '20px',
                                }}
                                src={mccpdc}
                                alt={'cost-plus'}
                              />
                            </a>
                          </div>
                        </TableCell> */}
              {/* <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                            Drug Price
                          </TableCell>
                          <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                            Pharmacy Name
                          </TableCell>
                        </TableRow>
                        {maPlanCard.prescriptionCoverages.map(
                          (prescriptionCoverage: any, index: any) => (
                            <TableRow
                              key={prescriptionCoverage.name + '_' + index}
                            >
                              <TableCell>
                                {convertCamelCaseToString(
                                  prescriptionCoverage.name
                                )}
                              </TableCell> */}
              {/* <TableCell style={{ textAlign: 'center' }}>
                              {prescriptionCoverage.costPlusDrugUnitPrice}
                            </TableCell> */}
              {/* <TableCell style={{ textAlign: 'center' }}>
                                {prescriptionCoverage.drugUnitPrice}
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                <p style={{ padding: '0px', margin: '5px' }}>
                                  {prescriptionCoverage.pharmacyName ===
                                    'MAIL_ORDER_PHARMACY'
                                    ? 'Mail Order Pharmacy'
                                    : convertSnakeCaseToHeading(
                                      prescriptionCoverage.pharmacyName.toLowerCase()
                                    )}
                                </p>
                                <p style={{ padding: '0px', fontWeight: '500' }}>
                                  {convertSnakeCaseToHeading(
                                    isEmpty(prescriptionCoverage.pharmacyAddress)
                                      ? ''
                                      : prescriptionCoverage.pharmacyAddress
                                  )}
                                </p>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </>
                )} */}
              {medicareAdvantagePlan.drugCosts?.length > 0 && (
                <>
                  <p>
                    Drug costs for the year
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigate({
                          pathname: '/plan-details',
                          search: `id=${medicareAdvantagePlan.id}&&type=mpd`,
                        })
                        storeScrollPosition()
                      }}
                    >
                      View complete plan details
                    </span>
                  </p>

                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                          Pharmacy Name
                        </TableCell>
                        <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                          Drug Price
                        </TableCell>
                      </TableRow>
                      {medicareAdvantagePlan.drugCosts.map(
                        (drugCost, index) => (
                          <TableRow key={drugCost.pharmacyId + '_' + index}>
                            <TableCell style={{ textAlign: 'center' }}>
                              {drugCost.pharmacyName}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {drugCost.fmtDrugCost}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </>
              )}
              <FooterWrapper>
                <SavePlanIconButton
                  planId={maPlanCard.id}
                  planType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
                  saved={isFavorite}
                  onAddSaved={onAddFavPlan}
                  onRemoveSaved={onRemoveFavPlan}
                />
                <div>
                  <Button
                    variant='text'
                    color='primary'
                    fontSize='14px'
                    bold={false}
                    endIcon={
                      isExpanded ||
                      medicareAdvantagePlan.name ===
                        customerStore.searchPlan ? (
                        <ArrowUp style={{ fontSize: '12px' }} />
                      ) : (
                        <ArrowDown style={{ fontSize: '12px' }} />
                      )
                    }
                    description={
                      isExpanded ||
                      medicareAdvantagePlan.name === customerStore.searchPlan
                        ? 'View Less Details'
                        : 'View More Details'
                    }
                    onClick={(e) => {
                      handleExpandCard(medicareAdvantagePlan.name)
                      fireEvent(
                        isExpanded ||
                          medicareAdvantagePlan.name ===
                            customerStore.searchPlan
                          ? 'viewLessCardDetails'
                          : 'viewMoreCardDetails',
                        e,
                        {
                          value:
                            !isExpanded ||
                            medicareAdvantagePlan.name !==
                              customerStore.searchPlan
                              ? 'expanding'
                              : 'collapsing',
                          planId: maPlanCard.id,
                          planType: MedicareQuotesType.MEDICARE_ADVANTAGE,
                          description: isExpanded
                            ? 'View Less Card Details'
                            : 'View More Card Details',
                        }
                      )
                    }}
                  >
                    <>
                      View{' '}
                      {isExpanded ||
                      medicareAdvantagePlan.name === customerStore.searchPlan
                        ? 'less'
                        : 'more'}{' '}
                      details
                    </>
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      setEnrollmentRequestPromptActive(true)
                    }}
                    disabled={enrollRequested}
                    additionalPadding={true}
                    description={`Attempted Enrollment in ${medicareAdvantagePlan.name}`}
                    id={`enroll ${medicareAdvantagePlan.name}`}
                  >
                    {enrollBtnText}
                  </Button>
                </div>
              </FooterWrapper>
            </MoreDetailsWrapper>
          </Wrapper>
        </Container>
      )}
    </>
  )
}

export default observer(MedicareAdvantageCard)
